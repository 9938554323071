import pricingPlans, { PricingPlan } from './pricingPlans'
import queryString from 'query-string'
import { navigate } from 'gatsby'
export function extractPlanFromLocation(
  location: Location
): PricingPlan | undefined {
  // Parses the query params of a location and returns the planset or undefined
  const { plansetId } = queryString.parse(location.search)
  return pricingPlans.visiblePlansById[plansetId as string]
}
