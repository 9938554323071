import React, { FunctionComponent } from 'react'
import {
  fbStartSignup,
  gtagEvent,
  gtagReportStartedSignupForAdwords,
} from '../../utils/analytics'
import { NewSignupForm } from './_newSignup'
import illustration from './illustration_signup.png'
import Layout from '../../components/layout'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import queryString from 'query-string'
import { extractPlanFromLocation } from '../../utils/pricingPlanFuncs'
import Box from '@material-ui/core/Box'
import TestimonialCarousel from '../../components/testimonial_carousel/testimonial_carousel'
import { navigate } from 'gatsby'

const stripePromise = loadStripe(process.env.STRIPE_PK_KEY)
interface Props {
  location: Location
}
const Signup: FunctionComponent<Props> = props => {
  const pricingPlan = extractPlanFromLocation(props.location)

  React.useEffect(() => {
    if (pricingPlan === undefined) {
      navigate('/pricing')
    }
    fbStartSignup()
    gtagEvent('1_visit_signup')
    gtagReportStartedSignupForAdwords()
  }, [1])
  return (
    <Layout
      backgroundColor="#FAFAFA"
      pageTitle={`Signup To LightTag `}
      // pageDescription={pricingPlan.description}
    >
      <Elements stripe={stripePromise}>
        <Container>
          <Grid
            style={{ height: '100vh' }}
            container
            alignItems={'center'}
            spacing={4}
            justify={'space-between'}
          >
            <Grid item>
              <Typography variant={'h4'}>We're not accepting new customers at this time</Typography>
            </Grid>
          {/*  <Grid item container direction="column" xs={6} md={6}>*/}
          {/*    <Box boxShadow={1} borderRadius={'4px'}>*/}
          {/*      <div*/}
          {/*        style={{*/}
          {/*          color: 'white',*/}
          {/*          background: '#7891e6',*/}
          {/*          padding: '1rem',*/}
          {/*          textAlign: 'center',*/}
          {/*          borderRadius: '4px',*/}
          {/*          fontWeight: 'bold',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <Typography variant={'h4'}>4 Questions</Typography>*/}
          {/*      </div>*/}
          {/*      <Box padding={2}>*/}
          {/*        {pricingPlan && <NewSignupForm pricePlan={pricingPlan} />}*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={6} container alignItems={"center"}>*/}
          {/*    <Grid item xs={12}>*/}
          {/*      <img src={illustration} style={{ width: '100%' }} />*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12}>*/}
          {/*    <TestimonialCarousel />*/}
          {/*  </Grid>*/}
          </Grid>
        </Container>
      </Elements>
    </Layout>
  )
}

export default Signup
